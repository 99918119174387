import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class LocalizationService {
    private translateService: TranslateService = inject(TranslateService);
    private currentLocaleId = '';

    init(): void {
        const lang =
            localStorage.getItem('quironCurrentLang') ||
            this.getUsersPreferredLocale('en-US').split('-')[0];
        this.translateService.setDefaultLang(lang);
        this.translateService.use(lang);

        this.setLocaleId(lang);
    }

    getCurrentLang(): string {
        return this.translateService.currentLang;
    }

    getCurrentLocaleId(): string {
        return this.currentLocaleId;
    }

    setLang(lang: string, store = false): void {
        this.translateService.use(lang);
        this.setLocaleId(lang);

        if (store) {
            localStorage.setItem('quironCurrentLang', lang);
        }
    }

    private setLocaleId(lang: string): void {
        switch (lang) {
            case 'pt':
                this.currentLocaleId = 'pt-BR';
                break;
            case 'es':
                this.currentLocaleId = 'es-ES';
                break;
            case 'en':
                this.currentLocaleId = 'en-US';
                break;
            case 'ptpt':
                this.currentLocaleId = 'pt-PT';
                break;
        }
    }

    private getUsersPreferredLocale(defaultValue: string): string {
        if (
            typeof window === 'undefined' ||
            typeof window.navigator === 'undefined'
        ) {
            return defaultValue;
        }

        const lang =
            window.navigator.languages && window.navigator.languages.length > 0
                ? window.navigator.languages[0]
                : window.navigator.language || defaultValue;

        return lang;
    }
}
